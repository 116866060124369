<template>
  <div class="work flex">
    <div class="work_left">
      <ul class="tab flex marB20">
        <li
          v-for="(item, index) in tab"
          :key="index"
          :class="{ active: active == index }"
          @click="tabs(index)"
        >
          {{ item }}
        </li>
      </ul>
      <!-- 报名信息 1 -->
      <ul
        v-show="active == '0'"
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <template>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column type="index" label="序号" width="140">
            </el-table-column>
            <el-table-column
              prop="banjiName"
              label="班级名称"
              width="170"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.auditstatus == 0">待审核</p>
                <p v-if="scope.row.auditstatus == 1">审核通过</p>
                <el-popover
                  placement="bottom"
                  title="未通过原因"
                  width="150"
                  trigger="hover"
                  popper-class="warp_popover"
                  :content="scope.row.auditmsg"
                >
                  <p v-if="scope.row.auditstatus == 2" class="cursorP" slot="reference" style="color:red">审核不通过</p>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-button @click="gotoLearn()" type="text" size="small"
                  >去学习</el-button
                >
                <el-button v-if="scope.row.auditstatus != 1" @click.stop="getWangTiTrainApplyDetail(scope.row.id)" type="text" size="small"
                  >编辑报名信息</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </ul>
      <!-- 去学习 2 -->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active == '1'"
      >
        <el-table :data="tableData" style="width: 100%">
          <el-table-column type="index" label="序号" width="140">
          </el-table-column>
          <el-table-column prop="courseName" label="课程名称" width="260">
          </el-table-column>
          <el-table-column prop="lecturer" label="讲师"> </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <p v-if="scope.row.trainstatus == 0">未考试</p>
              <p v-if="scope.row.trainstatus == 1">考试通过|未发证</p>
              <p v-if="scope.row.trainstatus == 2">考试未通过</p>
              <p v-if="scope.row.trainstatus == 3">已发证</p>
              <el-popover
                placement="bottom"
                title="注销原因"
                width="200"
                trigger="hover"
                :content="scope.row.revokeMsg"
              >
                <p v-if="scope.row.trainstatus == 4" slot="reference" style="color:red">已注销</p>
              </el-popover>
            </template>
          </el-table-column>
          <!-- <el-table-column label="状态">
            <template slot-scope="scope">
              <p v-if="scope.row.trainstatus == 0">未考试</p>
              <p v-if="scope.row.trainstatus == 1">考试通过|未发证</p>
              <p v-if="scope.row.trainstatus == 2">考试未通过</p>
              <p v-if="scope.row.trainstatus == 3">已发证</p>
              <p v-if="scope.row.trainstatus == 4">已注销</p>
            </template>
          </el-table-column> -->
          <el-table-column prop="address" label="操作">
            <template>
              <el-button @click="gotoLearn()" type="text" size="small"
                >去学习</el-button
              >
              <el-button @click="gotoLearn()" type="text" size="small"
                >去考试</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </ul>
      <!-- 去考试 3 -->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active == '2'"
      >
        <el-table :data="tableData" style="width: 100%">
          <el-table-column type="index" label="序号" width="140">
          </el-table-column>
          <el-table-column prop="banjiName" label="班级名称" width="320">
          </el-table-column>
          <!-- <el-table-column prop="lecturer" label="讲师"> </el-table-column> -->
          <el-table-column label="状态">
            <template slot-scope="scope">
              <p v-if="scope.row.trainstatus == 0">未考试</p>
              <p v-if="scope.row.trainstatus == 1">考试通过|未发证</p>
              <p v-if="scope.row.trainstatus == 2">考试未通过</p>
              <p v-if="scope.row.trainstatus == 3">已发证</p>
              <el-popover
                placement="bottom"
                title="注销原因"
                width="200"
                trigger="click"
                :content="scope.row.revokeMsg"
              >
                <p v-if="scope.row.trainstatus == 4" slot="reference" style="color:red">已注销</p>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template>
              <el-button @click="gotoLearn()" type="text" size="small"
                >去考试</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </ul>
      <!-- 我的证书 4 5 6-->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active == '3'"
      >
        <el-table :data="tableData" style="width: 100%">
          <el-table-column type="index" label="序号" width="90">
          </el-table-column>
          <el-table-column prop="userName" label="姓名" width="120">
          </el-table-column>
          <el-table-column prop="idcard" label="身份证号" width="220">
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <p v-if="scope.row.trainstatus == 0">未考试</p>
              <p v-if="scope.row.trainstatus == 1">考试通过|未发证</p>
              <p v-if="scope.row.trainstatus == 2">考试未通过</p>
              <p v-if="scope.row.trainstatus == 3">已发证</p>
              <p v-if="scope.row.trainstatus == 4">已注销</p>
            </template>
          </el-table-column>
          <el-table-column prop="certificateCode" label="证书编号" width="200">
          </el-table-column>
        </el-table>
      </ul>

      <!-- 分页 -->
      <div class="pages">
        <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="work.pageNum"
          :page-size="work.pageSize"
          layout="prev, pager, next, total, jumper"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 右边 优选推荐 -->
    <div class="work_right">
      <div class="top flex cursorP">
        <viewer :images="image" class="inline_block viewer">
          <img src="~/static/public/apps.png" alt="二维码" />
          <!-- <qr-code></qr-code> -->
        </viewer>
        <div class="text">点击放大QR,下载中国残联APP,安心面试,顺利求职!</div>
      </div>
      <div class="bottom">
        <div class="title">
          <h1 class="relative" v-if="location == '河南省'">
            河南省内职位推荐
            <span @click.stop="refresh">换一批</span>
          </h1>
          <h1 class="relative" v-if="location == '湖南省'">
            湖南省内职位推荐
            <span @click.stop="refresh">换一批</span>
          </h1>
          <h1 class="relative" v-if="location == ''">
            就近职位推荐
            <span @click.stop="refresh">换一批</span>
          </h1>
        </div>
        <ul class="marL10">
          <li v-for="item in recomdList" :key="item.index">
            <router-link :to="'/job/jobDetail/?id=' + item.id" target="_blank">
              <h4 class="line_clamp1">{{ item.jobName }}</h4>
            </router-link>

            <p class="mon_ey" v-if="item.jobSalary">{{ item.jobSalary }}</p>
            <p class="mon_ey" v-else>面议</p>
            <div class="company line_clamp1">
              {{ item.companyInfo.companyName }}
            </div>
            <el-divider></el-divider>
          </li>
        </ul>
      </div>
    </div>
          <el-dialog
        title="培训报名信息编辑"
        width="46%"
        v-if="isInvative"
        :visible.sync="isInvative"
        class="bg"
      >
    <invative :id="childId" @close="close($event)"></invative>
      </el-dialog>
  </div>
</template>

<script>
import Invative from "@/components/public/Invative";
export default {
  name: "work",
  components:{Invative},
  data() {
    return {
      messageList: '',
      active: "0",
      isShow: false,
      loading: false,
      image: [],
      tab: ["报名学习", "去学习", "去考试", "我的证书"],
      isInvative: false,
      total: 0,
      tableData: [],
      recomdList: [],
      interviewInfo: {},
      pagination: {
        pre: "上一页",
        next: "下一页",
      },
      status: {
        isHide: 0,
        type: "",
        des: "",
      },
      work: {
        pageNum: 1,
        pageSize: 10,
        trainstatus: "1",
      },
      params: {
        pageNum: 1,
        pageSize: 8,
      },
      typeDetail: "",
      location: "",
      childId:'',
    };
  },
  methods: {
    tabs(i) {
      this.active = i;
      this.tableData = [];
      if (this.active == "0") {
        this.work.trainstatus = 1;
      } else if (this.active == "1") {
        this.work.trainstatus = 2;
      } else if (this.active == "2") {
        this.work.trainstatus = 3;
      } else if (this.active == "3") {
        this.work.trainstatus = "4";
      }
      this.getWangTiTrainApplyPage();
      this.location = localStorage.getItem("locationSite");
    },
    // 请求培训列表的接口
    async getWangTiTrainApplyPage() {
      try {
        this.loading = true;
        let res = await this.$api.getWangTiTrainApplyPage(this.work);
        if (res.data.success) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.tableData.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
            this.status.des = "暂无数据!";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.status.isHide = 1;
        this.status.type = "wifi";
      }
    },
    //网梯培训报名_获取登录时的加密参数
    async gotoLearn() {
      try {
        let res = await this.$api.getWangTiParameterKey({});
        let data = res.data.data;
        if (res.data.success) {
          window.open(
            "https://kstb.webtrn.cn/sso/ssoLogin_tbLogin.action?loginId=" +
              data.loginId +
              "&sign=" +
              data.sign
          );
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 网梯报名信息回显
    async getWangTiTrainApplyDetail(id){
      console.log(id)
      this.isInvative = true;
      this.childId = id;
    },
    close(val){
      this.isInvative = val;
    },
    // 职位优选列表
    async queryAboutApplyJob() {
      // this.loading = true;
      try {
        let res;
        if (this.location == "湖南省") {
          res = await this.$api.queryAboutHopeJob(this.params);
        } else {
          res = await this.$api.queryAboutApplyJob(this.params);
        }
        // console.log("优选职位", res);
        if (res.data.success) {
          this.recomdList = res.data.data.records;
          // this.loading = false;
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    refresh() {
      this.queryAboutApplyJob();
      this.$message.success("成功!");
    },
    handleClose() {
      this.isShow = false;
      // try {
      //   let res = this.$api.getWangTiParameterKey();
      //   let data = res.data.data;
      //   if (res.data.success) {
      //     window.open(
      //       "https://kstb.webtrn.cn/sso/ssoLogin_tbLogin.action?loginId=" +
      //         data.loginId +
      //         "&sign=" +
      //         data.sign
      //     );
      //   } else {
      //     this.$message.error(res.data.msg);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },

    //分页
    handleCurrentChange(val) {
      this.work.pageNum = val;
      this.tabs(this.active);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.tabs(this.active);
    },
    handleClick(index, type) {
      this.interviewInfo = this.tableData[index].interviewInfo;
      this.interviewInfo.id = this.tableData[index].id;
      this.isShow = true;
      this.typeDetail = type;
    },
  },
  mounted() {
    let active = this.$route.query.active || 0;
    this.tabs(active);
    this.queryAboutApplyJob();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";

/deep/.el-table th.is-leaf,
.el-table td {
  text-align: center;
}
/deep/.el-table--enable-row-transition .el-table__body td {
  text-align: center;
}
.mon_ey {
  color: #f65150;
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
  margin: 6px 0;
}

.buttons {
  display: flex;
  width: 60%;
  margin: auto;
  justify-content: space-around;
}

.styl {
  color: red;
}

/deep/ .el-loading-spinner {
  top: 50%;
}

/deep/ .el-divider--horizontal {
  margin: 10px 0;
}

/deep/ .form {
  padding: 0 40px;

  .el-form-item {
    border-bottom: 1px solid #e1e1e1;
  }

  .buttons {
    button {
      width: 220px;
      margin-right: 20px;
    }
  }
}
.block {
  position: absolute;
  top: 20px;
  right: 0;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
