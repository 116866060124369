<template>
  <el-form ref="form" :model="form" label-width="130px" :rules="rules" v-if="form">
    <div class="form_input flex">
      <el-form-item label="姓名：" prop="userName" class="xb">
        <el-input size="small" placeholder="姓名" v-model="form.userName" class="width9011"></el-input>
      </el-form-item>
      <el-form-item label="性别：" label-width="80px" prop="userSex" class="xb">
        <el-select v-model="form.userSex" placeholder="请选择" class="width9011">
          <el-option label="男" value="6"></el-option>
          <el-option label="女" value="7"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <el-form-item label="一寸照片：" prop="photoUrl">
      <cd-upload v-model="form.photoUrl" :isLogo="true" className="avatars" tips="注意：请上传蓝底一寸标准照片，此照片用于制作证书。"
                 :on-success="handleAvatarlicenseUrl"/>
    </el-form-item>
    <el-form-item label="身份证号：" prop="idcard">
      <el-input placeholder="请输入身份证号" v-model="form.idcard" @blur="onblur()" class="width90"></el-input>
    </el-form-item>
    <el-form-item label="手机号码：" prop="iphone">
      <el-input placeholder="请输入手机号码" v-model="form.iphone" class="width90"></el-input>
    </el-form-item>
    <el-form-item label="文化程度：" prop="edu">
      <MulSelect :code="'user_edu'" class="width90" placeholder="请选择你的学历" @setMValue="setSelectValue($event, 'edu')"
                 :defaultValue="form.edu">
      </MulSelect>
    </el-form-item>
    <el-form-item label="电子邮箱：" prop="email">
      <el-input placeholder="请输入电子邮箱" v-model="form.email" class="width90"></el-input>
    </el-form-item>
    <el-form-item label="通讯地址：" prop="provinceid">
      <ThreeLink class="width90" placeholder="请选择通讯地址" :codeType="'provinceid'" :codeLevel="'-1'" :changeonselect="true"
                 @setMValue="
          setScasValue($event, form, ['provinceid', 'cityid', 'threeCityid'])
        " :defaultValue="[form.provinceid,form.cityid,form.threeCityid]"></ThreeLink>
      <el-input placeholder="请输入详细通讯地址" v-model="form.address" class="width90"></el-input>
    </el-form-item>
    <el-form-item label="年审地址：" prop="auditProvinceid">
      <ThreeLink class="width90" placeholder="请选择年审地址" :codeType="'provinceid'" :changeonselect="true" :codeLevel="'-1'"
                 @setMValue="
          setScasValue($event, form, [
            'auditProvinceid',
            'auditCityid',
            'auditThreecityid',
          ])
        " :defaultValue="[form.auditProvinceid,form.auditCityid,form.auditThreecityid]"></ThreeLink>
      <template prop="applyType">
        <el-radio v-model="form.applyType" label="1" style="font-size: 15px"><span style="font-size: 15px">残联年审机构</span>
        </el-radio>
        <el-radio v-model="form.applyType" label="2" style="font-size: 15px"><span style="font-size: 15px">政务窗口</span>
        </el-radio>
      </template>
    </el-form-item>
    <el-form-item label="原工作人员姓名：" prop="replaceUserName" class="phone">
      <el-input size="small" placeholder="请输入原工作人员姓名" v-model="form.replaceUserName" class="width9011"></el-input>
      <div class="text-tips">
        注意：如果您的工作是接替已报名人员的工作，请填写原工作人员姓名和手机号，以备顺利发证。如不涉及工作接替，无需填写此两项信息
      </div>
    </el-form-item>
    <el-form-item label="原工作人员电话：" prop="replaceIphone" class="xb ">
      <el-input size="small" placeholder="请输入原工作人员电话" v-model="form.replaceIphone" class="width9011"></el-input>

    </el-form-item>

    <div class="buttons text_C autos">
      <el-button type="primary" class="w170 bs_btn_oo" style="margin-left: 0px" @click="applyUpdate('form');"
                 v-if="isShow"
                 :loading="isLoading">{{ text }}
      </el-button>
    </div>
  </el-form>
</template>

<script>
import ThreeLink from '../selectComponents/ThreeLink';
import MulSelect from '@/components/selectComponents/MulSelect';

export default {
  props: ['invativeId', 'jobInfo', 'time', 'id'],
  components: {
    ThreeLink,
    MulSelect,
  },
  data() {
    return {
      isShow: true,
      cordId: '',
      text: '立即报名',
      isLoading: false,
      form: {
        id: '',
        address: '',
        applyType: '1',
        auditCityid: '',
        auditProvinceid: '',
        auditThreecityid: '',
        cityid: '',
        edu: '',
        email: '',
        idcard: '',
        iphone: '',
        photoUrl: '',
        provinceid: '',
        replaceIphone: '',
        replaceUserName: '',
        threeCityid: '',
        userName: '',
        userSex: '',
      },
      rules: {
        userName: [
          {
            required: true,
            message: '请填写姓名',
            trigger: 'change',
          },
        ],
        photoUrl: [
          {
            required: true,
            message: '请上传一寸照片',
            trigger: 'change',
          },
        ],
        userSex: [{required: true, message: '请选择性别', trigger: 'change'}],
        edu: [{required: true, message: '请选择学历', trigger: 'change'}],
        idcard: [
          {
            required: true,
            message: '请填写身份证信息',
            trigger: 'blur',
          },
          {
            pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
            message: '您的身份证号不正确请重新输入',
            trigger: 'blur',
          },
        ],
        iphone: [
          {
            required: true,
            message: '请填写手机号码',
            trigger: 'blur',
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '您的手机号码不正确请重新输入',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: '请填写电子邮箱',
            trigger: 'blur',
          },
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
            message: '电子邮箱格式不对',
            trigger: 'blur',
          },
        ],
        provinceid: [{required: true, message: '请选择地址', trigger: 'change,blur'}],
        address: [
          {
            required: true,
            message: '请填写详细通讯地址',
            trigger: 'change,blur',
          },
        ],
        auditProvinceid: [
          {
            required: true,
            message: '请填写详细工作地址',
            trigger: 'change,blur',
          },
        ],
      },
      value: [],
      options: [],
      disabled: false,
      locationCity: '',
    };
  },
  methods: {
    async info() {
      let res = await this.$api.getWangTiTrainApplyDetail({id: this.id});
      if (res.data.success) {
        (this.text = '保存'), (this.form = res.data.data);
        this.form.id = this.id;
      }
    },
    // 验证手机号是否重复
    async onblur() {
      if (this.id) {
      } else {
        try {
          if (this.form.idcard) {
            let res = await this.$api.wangTiTrainApplyIdcardCheck({Idcard: this.form.idcard});
            if (res.data.success == false) {
              this.$message.error(res.data.msg);
              console.log(res.data.success, 111);
            }
          }
        } catch (error) {
          console.log(error, '1111');
        }
      }
    },
    beforeAvatarUpload(file) {
      let type = ['image/jpeg', 'image/png'];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error('上传图片格式不正确');
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
      }
      return isImage && isLt2M;
    },
    erroupload() {
      this.$message({
        message: '图片上传失败，请重新上传！！',
        center: true,
      });
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarlicenseUrl(res, file) {
      this.form.photoUrl = res.msg;
    },
    // 提交表单接口
    applyUpdate(form) {
      this.isLoading = true;
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.id) {
            let res = await this.$api.wangTiTrainApply(this.form);
            if (res.data.success) {
              this.$message({
                message: '更新报名信息成功',
                type: 'success',
              });
              // this.form = {};
              this.$emit('close', false);
              // this.reload();
            } else {
              this.$message.error(res.data.msg);
              return false;
            }
          } else {
            let res = await this.$api.wangTiTrainApply(this.form);
            if (res.data.success) {
              this.$message({
                message: '报名成功',
                type: 'success',
              });
              this.form = {};
              this.$emit('close', false);
              // this.reload();
            } else {
              this.$message.error(res.data.msg);
              return false;
            }
          }
        } else {
          this.$message({
            message: '信息项未填写完整！',
            type: 'warning',
          });
          console.log('信息项不全!!');
          return false;
        }
        this.isLoading = false;
      });
    },
    applyMessa(val) {
      this.form.jobApplyDetailInDTO.applyMessage = val;
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = '';
        }
        let key = keys[i];
        form[key] = value;
      }
    },
  },
  mounted() {
  },
  created() {
    this.locationCity = localStorage.getItem('locationSite');
    if (this.id) {
      this.info();
    }
  },
};
</script>

<style lang="less" scoped>
.bg {
  //top: -80px;
}

/deep/ .el-upload__tip {
  color: red;
}

/deep/ .el-form-item__content {
  margin-left: 0px;
}

.phone {
  position: relative;
}

.text-tips {
  position: absolute;
  top: 0;
  left: 235px;
  color: red;
  width: 50%;
  line-height: 1.5;
}

.autos {
  display: flex;
  margin-left: 0px;
  justify-content: center;

  .el-button {
    margin-left: 0px;
  }
}

/deeep/ .el-dialog__title {
  color: orange;
}

/deep/ .el-input__inner {
  height: 34px;
}

.checked {
  span {
    color: #999999;
  }

  .el-form-item__content {
    margin-left: 40px;
  }
}

.width9011 {
  width: 170px;
}

.xb {
  display: inline-block;
}
</style>
